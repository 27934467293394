import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useCharacters } from '@lib/core/characters/hooks';
import { isCharacterByIdentifiers } from '@lib/core/characters/utils/filters';
import { useParsedProducts } from '@lib/core/products/hooks/useParsedProducts';
import { TParsedProductInstance } from '@lib/core/products/types';
import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import { PREFERENCES_QUERY, PRODUCT_PREFERENCES_URL_PARAM } from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { useKioskRoot } from '@lib/core/service/hooks/useKioskRoot';
import { fetchSituationalResultPageProducts, resetResultPageProducts } from '@lib/core/service/slices/kioskRootSlice';
import { prependBasename } from '@lib/core/service/utils';
import { useProductList, useUser } from '@lib/core/users/hooks';
import { useFidelityCard } from '@lib/core/users/hooks/useFidelityCard';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { CHARACTERS_URL_PARAM, PRODUCT_CATEGORY_QUERY } from '@lib/tools/shared/helpers/consts';
import { PAGES } from '@lib/tools/views/urls';

import { ResultPageSkeleton } from '@components/web/src/organisms/Skeleton/ResultPageSkeleton/ResultPageSkeleton';
import { SituationalResultPage } from '@components/web/src/templates/ResultPages/Kiosk/SituationalResultPage/SituationalResultPage';

export interface IGroupedProduct {
  characterId: string;
  products: TParsedProductInstance[];
}

const SituationalResultContainer: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const [showContent, setShowContent] = useState(false);
  const [groupedProducts, setGroupedProducts] = useState<IGroupedProduct[]>([]);

  const { locale, productCategory } = useApp();
  const { isUserDataLoading, userBestMatchCharacterForCurrentServiceProductCategory } = useUser();
  const { characters, isCharactersLoading } = useCharacters();
  const { retailerStoreType } = useRetailerLocation();
  const { isFidelityCardScanned } = useFidelityCard();
  const { products, isProductsLoading } = useKioskRoot();
  const characterStringFromUrl: string | null = searchParams.get(CHARACTERS_URL_PARAM) || '';
  const preferencesFromUrl: string | null = searchParams.get(PRODUCT_PREFERENCES_URL_PARAM) || '';

  const charactersData = characterStringFromUrl
    ? characters.filter(character =>
        isCharacterByIdentifiers(character, [...new Set(characterStringFromUrl.split('-'))]),
      )
    : [userBestMatchCharacterForCurrentServiceProductCategory];

  const charactersIdentifiers = charactersData.filter(i => !!i).map(character => character.characterId);

  useEffect(() => {
    if (!products.length) {
      const params = {
        [PRODUCT_CATEGORY_QUERY]: productCategory,
        offset: 0,
        ...(preferencesFromUrl && { [PREFERENCES_QUERY]: preferencesFromUrl }),
      };

      dispatch(fetchSituationalResultPageProducts({ charactersIdentifiers, params }));
    }

    return () => {
      if (!window.location.pathname.includes(PAGES.vinhood.product)) {
        dispatch(resetResultPageProducts());
      }
    };
  }, []);

  const { parseProductsArray } = useParsedProducts();
  const { isWishlistProductListLoading } = useProductList();

  useEffect(() => {
    if (products.length) {
      const groupedProductsData: IGroupedProduct[] = products
        .filter(productsData => productsData.length)
        .map(productArray => ({
          characterId: productArray[0]?.character?.identifier,
          products: parseProductsArray(productArray),
        }));
      setGroupedProducts(groupedProductsData);
    }
  }, [products, isWishlistProductListLoading]);

  const navigateToProductCatalogPage = (characterId?: string) =>
    navigate(prependBasename(PAGES.vinhood.catalog, characterId && { [CHARACTERS_URL_PARAM]: characterId }));

  useEffect(() => {
    const isDataLoading = isProductsLoading || isUserDataLoading || isCharactersLoading;

    if (!isDataLoading) {
      setTimeout(() => {
        setShowContent(true);
      }, 2000);
    }
  }, [isProductsLoading, isUserDataLoading, isCharactersLoading, characterStringFromUrl]);

  useEffect(() => {
    MixpanelTracker.events.resultPageView(charactersData);
  }, []);

  return (
    <>
      {showContent ? (
        <SituationalResultPage
          shouldHideRatingButton
          shouldHideShare
          charactersData={charactersData}
          groupedProducts={groupedProducts}
          isProductsLoading={isProductsLoading}
          locale={locale}
          navigateToProductCatalogPage={navigateToProductCatalogPage}
          productCategory={productCategory}
          shouldHideWishlist={!isFidelityCardScanned}
          storeType={retailerStoreType}
        />
      ) : (
        <ResultPageSkeleton isFromSituationTest />
      )}
    </>
  );
};

export default SituationalResultContainer;
